@font-face {
    font-family: 'Montserrat-Bold';
    src: url('./assets/Fonts/Montserrat-Bold.ttf')  format('ttf'),
    url('./assets/Fonts/Montserrat-Bold.otf') format('otf'),
    url('./assets/Fonts/Montserrat-Bold.woff') format('woff'),
    url('./assets/Fonts/Montserrat-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('./assets/Fonts/Montserrat-SemiBold.woff') format('woff'),
    url('./assets/Fonts/Montserrat-SemiBold.otf') format('otf'),
    url('./assets/Fonts/Montserrat-SemiBold.woff2')  format('woff2');
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url('./assets/Fonts/Montserrat-Regular.ttf')  format('ttf'),
    url('./assets/Fonts/Montserrat-Regular.otf')  format('otf'),
    url('./assets/Fonts/Montserrat-Regular.woff')  format('woff'),
    url('./assets/Fonts/Montserrat-Regular.woff2')  format('woff2');
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: url('/assets/Fonts/Montserrat-Medium.ttf')  format('ttf'),
    url('/assets/Fonts/Montserrat-Medium.otf')  format('otf'),
    url('/assets/Fonts/Montserrat-Medium.woff')  format('woff'),
    url('/assets/Fonts/Montserrat-Medium.woff2')  format('woff2');
}

@font-face {
    font-family: 'Poppins-Light';
    src: url('/assets/Fonts/Poppins-Light.ttf')  format('ttf'),
  
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('/assets/Fonts/Poppins-Bold.ttf')  format('ttf'),
  
}


body {
    /* background-image: url(http://wroomservices.hebergement-com4design.fr/wp-content/uploads/2020/06/wroom-car.jpg); */
    /* background-size: cover; */
    /* background-position: bottom center; */
    /* background-repeat: no-repeat; */
    /* box-shadow: inset 100vw 0 0 0 rgba(0, 0, 0, 0.5); */
    /* border-color: rgba(0, 0, 0, 1); */
    width: 100vw;
    height: 100vh;
    
}

.App {
    display: flex;
    flex-direction: column;
    max-width: 1240px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    padding-bottom: 150px;

}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}


.text-align-left{
text-align: left;
}
.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.big-wrapper {
    display: flex;
    width: 100%;
}

.big-wrapper > div:first {
    width: 52.488%
}

/* input[type=radio],
input[type=checkbox]{
  visibility: hidden;
  position: absolute;
}
input[type=radio] + label:before,
input[type=checkbox] + label:before{
  height:12px;
  width:12px;
  margin-right: 2px;
  content: " ";
  display:inline-block;
  vertical-align: baseline;
  border:1px solid #777;
}
input[type=radio]:checked + label:before,
input[type=checkbox]:checked + label:before{
  background:gold;
}

/* CUSTOM RADIO AND CHECKBOX STYLES */
input[type=radio] + label:before{
  border-radius:50%;
}
input[type=checkbox] + label:before{
  border-radius:2px;
} */

input[type=radio]:checked + label {
    color: #8FCBB1;
}

.pricing-div{
    background-color: rgba(143, 203, 177, 0.1);
    width: 100%; 
    border-radius: 5px;
    /* padding:10px */
}
.flex-start-align{align-items:flex-start;margin-left: 10px;}
.flex-end-align{
align-items: flex-end;
    justify-content: flex-end;
    margin-right: 10px;
}
@media screen and  (max-width:1024px)
{

    .big-wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .elementor-element-5a5fa8d {
        width: 90vw!important;
        margin-bottom: 15px;
    }

    .booking-block {
        /* background: red!important; */
        width: 100vmin!important;
        max-width: 100vmin!important;
        border-radius: 0!important;
        box-shadow: none!important;
        margin: 20px 20px 20px 20px;
        padding: 30px 40px 10px 40px;
    }

    .booking-block .width {
        /* background: red!important; */
        width: 90%!important;
    }

    .booking-block .button {
        width: 90%!important;
    }

    .booking-block .list {
        width: 90%!important;
    }
}

.min-width-bloc{
min-width: 50%;
}

.black{
    color: #222222;
}

.booking {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 2px solid white;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding-bottom: 1vmax;
   
}

.booking-block {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, .16);
    border-radius: 15px;
    align-self: center;


}
.title {
    color: #222222;
    font-family: "Montserrat-Bold";
    font-size: 22px;
}

.text {
    color: #737B91;
    font-family: "Montserrat-Regular";
    font-size: 13px;
}
.text-medium {
    color: #222222;
    font-weight: 600;
    font-family: "Montserrat-Regular";
    font-size:  14px;
}
.date-picker {
    width:100%;
    height: 50px;
   background-color: #F9F9F9;
    font-family: "Montserrat-Medium";
    color: #222222;
    font-weight: 600;
    font-family: "Montserrat-Regular";
    font-size:  13px;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    outline: 0px;
    align-items: center;
    justify-content: center;
   color: #737B91;
   padding-left:10px;

}
.success-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
}
.success-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 30%
}
.success-text{
    max-width: 38%;
    text-align: center;
    color: #8C89A2;
    font-size: 14px;
    font-family: "Poppins-Light"
}
.success-title{
    text-align: center;
    color: #3F3A64;
    font-size: 20px;
    font-family: "Poppins-Bold"
}
.justify-center{
    justify-content: center;
}
.select-wrapper{
    background-color: #F9F9F9 !important;
    /* height: 3.1vmax; */
    border-radius: 10px;
    border: 0.5px solid #EFEFEF;
    display: flex;
    align-items: center;
    justify-content: center; 
    width: 95%;
    height: 50px;
}

option:first {
    color: #7a7a7a;
    opacity: 0.7;
}

.select{
    border:0px;
    outline:none;
    color: #737B91;
    font-weight: 600;
    font-family: "Montserrat-Regular";
    font-size:  13px;
    width: 20vmax;
    background-color: transparent;
    background:none;
    -moz-appearance: none; 
    -webkit-appearance: none; 
    appearance: none;
    width: 90%;
}
.line{
    background-color: #00B456;
    align-self: flex-start;
}
.bookings-list {
    display: flex;
    justify-content: flex-end;
}

.price {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: flex-start;
    justify-content: center;
    /* background-color: #F9F9F9 !important; */
    margin-top: 10px;
    border-radius: 5px;
    margin-bottom: 10px
}
.summary{
    background-color: white;
    display: flex;
    flex-direction: column;
     align-items: center;   
}
.swal-overlay {
    background-color: transparent;
    top: 170px!important;
    bottom: auto!important;
  }
  .swal-modal {
    box-shadow: 1px 1px 3px 3px rgba(0, 0, 0, .2);
    
  }

  
.list {
   
    background-color: #F9F9F9 !important;
    border-radius: 5px;
    position: absolute;
    z-index: 999;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    flex-direction: column;
    display: flex;
  /* width: 80%; */
  width: 35.39%;
    border: 1px solid #EFEFEF;
}
.my-bookings{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1vmax;
}
.my-bookings-list{
    display: flex;
    flex-direction: column;
    width: 80vmax; 
    background-color: white !important;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, .16);
    border-radius: 5px;
    align-content: center;
    align-items: center;
}
.label{
    font-family: "Montserrat-Medium";
    color: #242323;
    font-size:  15px;
}
.list-text{
    color: #737B91;
    font-family: "Montserrat-Regular";
    font-size:14px;
}
.inputStyle {
    width:92%;
    height: 50px;
   border: none;
   background-color: transparent;
    font-family: "Montserrat-Medium";
    color: #222222;
    font-weight: 600;
    font-family: "Montserrat-Regular";
    font-size:  13px;

   
}

.close{
    display: flex;
    align-items: flex-end;
   
    justify-content: flex-end;
}
.radio{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border: 0;
    outline: 0;
    cursor: pointer;
   background-color: transparent;
  
}
.inside-radio{
   
    outline: 0;
    cursor: pointer;
    border: 1px solid #00B456;
    box-shadow: .5px .5px 2px 2px rgba(0, 0, 0, .1)
}
.font-size{
    font-size:  15px;
}
@media screen and  (min-width: 1024px){
    .booking-block {
        top: 15vmin; 
        /* width: 400px;  */
        right: 5vmax;
        padding: 1%;
        margin: 20px 20px 20px 20px;
        padding: 30px 40px 10px 40px;
        width: 35.39%;
    }
    
    .inputStyle {
        /* width:92%; */
        /* height: 40px; */
        color: #222222;
        /* background-color: blueviolet; */
        /* margin-bottom: .5vmax; */
    }
    /* .date-picker {
        width: 90%;
        
 
    } */
    .width {
        width: 100%;
        margin-bottom: .5vmax;
   
    }
    .button{
        height:40px;
        width: 100%;
        
    }
    .line{ 
        width: 5vmax;
        height: .15vmax;
    }
    .bookings-list {
        width: 93vmax;
    }
    .summary{
        /* width: 65vmax; */
        /* height: 50vmax; */
    }

    .summary-width{
        width:29vmax
    }
   
    .list {
        /* width: 22vmax; */
        /* top: 13vmax;
        margin-top: 1vmax; */
    }
  
 
.modal{
    width:65vmax
}
  }
  /* @media screen and  (min-width:600px) and (max-width:921px){
    .App {
        align-items: center;
      }
  
      .booking-block {
          position: relative;
          width: 80vmin;
  
          top: 5vmin;  
      }
      .title{
          font-size: 2.5vmax;
      }
      .text-semibold{
          font-size: 2.2vmax;
      }
      .text {
          font-size: 1.8vmax;
      }
      .text-medium {
         
          font-size: 1.8vmax;
      }
      .inputStyle {
       width: 56vmin;
          height: 4.5vmax;
          background-clip: padding-box;
          font-size: 1.5vmax;
     
           margin-bottom: 10px; 
   
      }
      .date-picker {
          font-size: 1.5vmax;
          color: #737B91;
          width: 24vmin;
          height: 4vmax;
         
      }
      .width {
          width: 62vmin;
          margin-top: .5vmax;
          margin-bottom: 1vmax;
      }
      .select-wrapper{
          width: 62vmin;
          height: 5vmax; 
      }
      .select{
          -moz-appearance: none; 
          -webkit-appearance: none; 
          appearance: none;
          height: 5vmax;
         width: 56vmin;
          font-size: 1.5vmax;
          background-color: transparent;
      }
      .button{
          height:4.5vmax;
          width: 60vmin;
          font-size: 1.5vmax;
          width: 23vmin;
      }
      .line{ 
          width: 20vmin;
          height: .3vmax;
      }
  
      .bookings-list {
          width: 80vmin;
   
      }
      .price {
          width: 62vmin;
      }
      .summary{
   
          width: 70vmax;
          height: 170vmax; 
          align-self: center;
       
   
      }
      .summary-width{
          width:60vmax; 
        

      }
      .font-size{
          font-size: 2vmax;
      }
      .list {
          width: 60vmin;
          margin-top: 12vmax;
        
      }
      .my-bookings-list{
          width: 90vmin; 
      }
      .label{
          font-size: 1.2vmax;
      }
      .list-text{
          font-size: 1.5vmax;
      }
      .modal{
          width:85vmin
      }
    
  } */
  @media (orientation: portrait) {
    .swal-overlay {
        background-color: rgba(0,0,0,0.75);
      }
  }
  
  @media screen and  (max-width:1024px){
    .App {
      align-items: center;
    }

    .booking-block {
        position: relative;
        width: 80vmin;
       max-width: 500px;
        /* top: 5vmin;  */
    }
    /* .title{
        font-size: 2.5vmax;
    }
    .text-semibold{
        font-size: 2.2vmax;
    }
    .text {
        font-size: 1.8vmax;
    }
    .text-medium {
       
        font-size: 1.8vmax;
    } */
    .inputStyle {
        /* width: 60vmin; */
        /* height: 4.5vmax; */
        background-clip: padding-box;
        /* font-size: 1.5vmax; */
        /* margin-bottom: 10px; */
 
    }
    .date-picker {
        /* font-size: 1.5vmax; */
        color: #737B91;
        /* width: 24vmin; */
       
    }
    .width {
        width: 80%;
        margin-top: .5vmax;
        margin-bottom: 1vmax;
    }
    /* .select-wrapper{
        width: 62vmin;
        height: 5vmax; 
    } */
    .select{
        -moz-appearance: none; 
        -webkit-appearance: none; 
        appearance: none;
        /* height: 5vmax;
       width: 56vmin; */
        /* font-size: 1.5vmax; */
        background-color: transparent;
    }
    .button{
        height:40px;
        /* width: 60vmin; */
        font-size: 15px;
        width: 80% !important;
    }
    .line{ 
        width: 20vmin;
        height: .3vmax;
    }

    .bookings-list {
        width: 80vmin;
 
    }
    /* .price {
        width: 62vmin;
    } */
    .summary{
        /* position: relative; */
        width: 85vmin;
        /* height: 170vmax;  */
        /* align-self: center; */
        /* margin-top: 7vmax; */
    }
    .summary-width{
        width:80vmin
    }
    /* .font-size{
         font-size: 2vmax; 
    } */
    .list {
        /* width: 60vmin; */
         margin-top: 15px; 
      
    }
    .my-bookings-list{
        width: 90vmin; 
    }
    /* .label{
        font-size: 1.2vmax;
    }
    .list-text{
        font-size: 1.5vmax;
    }
    .modal{
        width:85vmin
    } */
  }







.border {
    border: 2px solid white;
}

.bottom {
    position: absolute;
    top: 85%;
    right: 0;
    bottom: 0;
    left: 48%;
}

.flex {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.space-between {
    justify-content: space-between;
}
.green{
    color: #00B456;
    cursor: pointer;
}



.iframe {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: 100%;
    border: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    z-index: 999999;
    height: 100%;
    ;
}

.center {
    align-content: center;
    align-items: center;
}

.text-semibold{
    font-family: "Montserrat-SemiBold";
    color: #222222;
    font-size:  15px;
}

.summary::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE and Edge */
  .summary {
    -ms-overflow-style: none;
  }
.inputStyle:focus{
    outline-offset: 0;
}


input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #7a7a7a;
    opacity: 0.7;
    font-weight: 600;
    font-family: "Montserrat-Regular";

    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #737B91;
    font-weight: 600;
    font-family: "Montserrat-Regular";

}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #737B91;
    font-weight: 600;
    font-family: "Montserrat-Regular";
   
}

.lds-roller {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.35);
    z-index: 999999;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

}

.message {
    width: 100%;
    display: flex;
    position: absolute;
    top: 60%;
    left: 0;
    right: 0;
    bottom: auto;
    z-index: 999999;
    align-items: center;
    justify-content: center
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 50px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}


.fade-in {
    animation: fadeIn ease 5s;
    -webkit-animation: fadeIn ease 5s;
    -moz-animation: fadeIn ease 5s;
    -o-animation: fadeIn ease 5s;
    -ms-animation: fadeIn ease 5s;
  }
  
  
  @keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-moz-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-o-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-ms-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.elementor-9 .elementor-element.elementor-element-3b2ddfe .elementor-heading-title {
    color: #FFFFFF;
    font-family: "Poppins", Sans-serif;
    font-size: 40px;
    font-weight: bold;
    line-height: 1.4em;
    letter-spacing: 1px;
}